import { motion } from 'framer-motion';
import React from 'react';
import ContactForm from './EmailForm';

function Contact() {
  return (
    <div>
      <motion.div
        position="relative"
        size="100%"
        animate={{ y: [-50, 0], opacity: [0, 1] }}
        background="transparent"
      >
        <h1 className="text-5xl md:text-6xl lg:text-7xl my-4">Contact Me</h1>
      </motion.div>
      <motion.div
        position="relative"
        size="100%"
        animate={{ x: [100, 0], opacity: [0, 1] }}
        background="transparent"
      >
        <ContactForm />
      </motion.div>
    </div>
  );
}

export default Contact;
