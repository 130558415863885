import React from 'react';
import { motion } from 'framer-motion';

function ProjectCard({ title, url, img, description }) {
  return (
    <a className="ProjectCard" href={url} target="_blank" rel="noopener noreferrer">
      <div className="m-3 text-ghost-white border-0 rounded-xl">
        <header className="border-b rounded-t-xl border-elec-blue bg-charcoal text-4xl py-4">
          {title}
        </header>
        <div className="text-black text-opacity-0 hover:text-opacity-100">
          <motion.div
            className="z-50"
            animate={{ opacity: 1 }}
            opacity={1}
            whileHover={{ opacity: 0.3 }}
            background="transparent"
            position="relative"
            size="100%"
          >
            <img className="border rounded-b-xl" src={img} alt={title} />
          </motion.div>
          <span className="text-xl absolute top-1/2 inset-x-0 z-0 px-8">{description}</span>
        </div>
      </div>
    </a>
  );
}

export default ProjectCard;
