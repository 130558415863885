import { motion } from 'framer-motion';
import React from 'react';
import ProjectCard from './ProjectCard';

const GITHUB_URL = 'https://github.com/mackcooper1408';

const drinklyDesc = 'an app for finding your favorite cocktails | React, Redux';
const blogDesc = 'a micro blog for writing your favorite rants | React, Redux, Express, PostgreSQL';
const warblerDesc = 'a Twitter clone | Flask, SQLAlchemy, PostgreSQL';

function Portfolio() {
  return (
    <>
      <motion.div
        animate={{ y: [-50, 0], opacity: [0, 1] }}
        background="transparent"
        position="relative"
        size="100%"
      >
        <h1 className="text-7xl my-4">Projects</h1>
      </motion.div>
      <div className="Portfolio grid lg:grid-cols-3 m-8">
        <motion.div
          animate={{ y: [200, 0], opacity: [0, 1] }}
          transition={{ y: { duration: 0.8 } }}
          whileHover={{ scale: 1.05 }}
          background="transparent"
          position="relative"
          size="100%"
        >
          <ProjectCard
            title="Drink.ly"
            url={`${GITHUB_URL}/drinkly`}
            img="cocktail.jpg"
            description={drinklyDesc}
          />
        </motion.div>
        <motion.div
          animate={{ y: [200, 0], opacity: [0, 1] }}
          transition={{ y: { duration: 1 } }}
          whileHover={{ scale: 1.05 }}
          background="transparent"
          position="relative"
          size="100%"
        >
          <ProjectCard
            title="Micro Blog"
            url={`${GITHUB_URL}/redux-microblog`}
            img="blog.jpg"
            description={blogDesc}
          />
        </motion.div>
        <motion.div
          animate={{ y: [200, 0], opacity: [0, 1] }}
          transition={{ y: { duration: 1.3 } }}
          whileHover={{ scale: 1.05 }}
          background="transparent"
          position="relative"
          size="100%"
        >
          <ProjectCard
            title="Warbler"
            url={`${GITHUB_URL}/warbler`}
            img="bird.jpg"
            description={warblerDesc}
          />
        </motion.div>
      </div>
    </>
  );
}

export default Portfolio;
