import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="Home text-charcoal container mx-auto m-4 mt-60">
      <motion.div
        position="relative"
        size="100%"
        animate={{ y: [-100, 0], opacity: [0, 1] }}
        background="transparent"
      >
        <header className="text-5xl md:text-7xl lg:text-9xl my-2">
          <h1>Mack Cooper</h1>
        </header>
      </motion.div>
      <motion.div
        position="relative"
        className="my-2"
        size="100%"
        animate={{ x: [-100, 0], opacity: [0, 1] }}
        background="transparent"
      >
        <h2 className="text-3xl md:text-5xl lg:text-7xl">Software Engineer</h2>
      </motion.div>
      <motion.div
        position="relative"
        size="100%"
        animate={{ y: [100, 0], opacity: [0, 1] }}
        whileHover={{ scale: 1.05 }}
        background="transparent"
      >
        <button className="btn-elec-blue my-2 text-xl lg:text-3xl hover:bg-charcoal">
          <Link to="/projects">See My Work</Link>
        </button>
      </motion.div>
    </div>
  );
}

export default Home;
