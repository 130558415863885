import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Navbar.css';

function Navbar() {
  const [display, setDisplay] = useState(false);

  const handleChange = () => {
    setDisplay(!display);
  };

  return (
    <div className="Navbar border-b-4 border-double border-powder bg-elec-blue">
      <nav className="flex flex-col md:flex-row justify-between">
        <label htmlFor="nav-toggle" className="md:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="64px" height="64px">
            <path
              fill="#F4F4F9"
              d="M86.5 52h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 52 86.5 52zM86.5 67h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 67 86.5 67z"
            />
            <g>
              <path
                fill="#F4F4F9"
                d="M86.5,82h-45c-1.7,0-3-1.3-3-3s1.3-3,3-3h45c1.7,0,3,1.3,3,3S88.2,82,86.5,82z"
              />
            </g>
          </svg>
        </label>
        <input
          type="checkbox"
          className="mx-4 my-2 block hidden self-start"
          name="nav-toggle"
          id="nav-toggle"
          checked={display}
          onChange={handleChange}
        />
        <ul
          className="hidden flex-col md:flex md:flex-row items-center text-2xl text-ghost-white"
          id="menu"
        >
          <li className="mx-4 my-2 hover:text-powder">
            <motion.div
              position="relative"
              size="100%"
              whileHover={{ scale: 1.15 }}
              background="transparent"
            >
              <Link to="/" onClick={() => setDisplay(false)}>
                Home
              </Link>
            </motion.div>
          </li>
          <li className="mx-4 my-2 hover:text-powder">
            <motion.div
              position="relative"
              size="100%"
              whileHover={{ scale: 1.15 }}
              background="transparent"
            >
              <Link to="/projects" onClick={() => setDisplay(false)}>
                Projects
              </Link>
            </motion.div>
          </li>
          <li className="mx-4 my-2 hover:text-powder">
            <motion.div
              position="relative"
              size="100%"
              whileHover={{ scale: 1.15 }}
              background="transparent"
            >
              <Link to="/contact" onClick={() => setDisplay(false)}>
                Contact
              </Link>
            </motion.div>
          </li>
        </ul>
        {/* <p className="text-4xl text-ghost-white mx-4 my-2">Mack Cooper</p> */}
      </nav>
    </div>
  );
}

export default Navbar;
