import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Home';
import Navbar from './Navbar';
import Contact from './Contact';
import Portfolio from './Portfolio';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="contact" element={<Contact />} />
          <Route path="projects" element={<Portfolio />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
