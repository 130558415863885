import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from '../../config';
import { motion } from 'framer-motion';

function ContactForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const sendEmail = async (evt) => {
    evt.preventDefault();

    setIsSubmitting(true);
    try {
      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, evt.target, USER_ID);
    } catch (err) {
      console.error(err.text);
    } finally {
      setIsSubmitting(false);
      navigate('/');
    }
  };

  return (
    <div className="ContactForm my-4 container mx-auto w-full md:w-5/6 lg:w-2/3 border-8 border-double border-ghost-white rounded-lg bg-charcoal text-ghost-white">
      <form className="flex flex-col items-center my-4" onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" />
        <div className="m-8 md:m-3 flex flex-col items-start w-10/12">
          <input
            className="border-2 md:border-0 md:border-b-2 border-ghost-white rounded md:rounded-none bg-charcoal w-full focus:outline-none p-3"
            type="text"
            name="from_name"
            placeholder="Full Name"
          />
        </div>
        <div className="m-8 md:m-3 flex flex-col items-start w-10/12">
          <input
            className="border-2 md:border-0 md:border-b-2 border-ghost-white rounded md:rounded-none bg-charcoal w-full focus:outline-none p-3"
            type="email"
            name="to_email"
            placeholder="Email"
          />
        </div>
        <div className="m-8 md:m-3 flex flex-col items-start w-10/12">
          <textarea
            className="border-2 md:border-0 md:border-b-2 border-ghost-white rounded md:rounded-none bg-charcoal w-full focus:outline-none p-3"
            name="message"
            placeholder="Message"
            rows={5}
          />
        </div>
        <motion.div
          style={{ position: 'relative', background: 'transparent', width: '100%' }}
          whileHover={{ scale: 1.05 }}
        >
          <button className="btn-elec-blue hover:bg-gray-600" type="submit" disabled={isSubmitting}>
            Send Email
          </button>
        </motion.div>
      </form>
    </div>
  );
}

export default ContactForm;
